// Values valid for dev/prod/local
export const environmentCoolLifeBase = {
  production: false,
  debug: true,
  appName: "coollife",
  continent: "Europe",
  apiUrl: "", // will be completed during ng build or ng serve based on configuration
  geoserverUrl: "", // will be completed during ng build or ng serve based on configuration
  wikiUrl: "",
  enableFeedback: false,
  enableLogin: true,
  logoUrl: "../../../assets/first-page/coollife/CoolLIFE-color.svg",
  partnersLogosUrl: "../../../assets/first-page/coollife/partners.jpg",
  favicon: "../../../assets/first-page/coollife/coollife-favicon.ico",
  websiteUrl: "https://coollife.revolve.media/",
  dataSetURl: "https://gitlab.com/eu_coollife",
  repositoryUrl: "https://vlhtuleap.hevs.ch/plugins/git/git-eranet",
  introText:
    "The CoolLIFE project aims to address the need for sustainable solutions to the EU’s rising demand for space cooling in buildings. The project will develop open-source tools which encourage the consideration of green cooling solutions in public and private decision-making, planning, design, and implementation processes.",
  fundingText:
    "The CoolLIFE project has received funding from the LIFE Programme of the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or CINEA. Neither the European Union nor CINEA can be held responsible for them.",
  fundingImg: "../../../assets/first-page/coollife/coollife_funding_img.jpg",
  centerMap: [50, 11],
  zoom: 5,
  prefixWorkspaceName: "hotmaps:",
  nuts_CQL_FILTER: "(WITHIN(geom,polygon((",
  scaleRequest: [
    {
      label: "NUTS 0",
      tooltip: "Countries",
      option: {
        layers: "hotmaps:" + "nuts",
        format: "image/png8",
        transparent: true,
        version: "1.3.0",
        cql_filter: "stat_levl_ = " + 0 + " AND year='2013-01-01'",
        srs: "EPSG:4326",
        zIndex: 5,
      },
    },
    {
      label: "NUTS 1",
      tooltip: "Major socio-economic regions",
      option: {
        layers: "hotmaps:" + "nuts",
        format: "image/png8",
        transparent: true,
        version: "1.3.0",
        cql_filter: "stat_levl_ = " + 1 + " AND year='2013-01-01'",
        srs: "EPSG:4326",
        zIndex: 5,
      },
    },
    {
      label: "NUTS 2",
      tooltip: "Provincies or regions",
      option: {
        layers: "hotmaps:" + "nuts",
        format: "image/png8",
        transparent: true,
        version: "1.3.0",
        cql_filter: "stat_levl_ = " + 2 + " AND year='2013-01-01'",
        srs: "EPSG:4326",
        zIndex: 5,
      },
    },
    {
      label: "NUTS 3",
      tooltip: "Small regions or departments",
      option: {
        layers: "hotmaps:" + "nuts",
        format: "image/png8",
        transparent: true,
        version: "1.3.0",
        cql_filter: "stat_levl_ = " + 3 + " AND year='2013-01-01'",
        srs: "EPSG:4326",
        zIndex: 5,
      },
    },
    {
      label: "LAU 2",
      tooltip: "Municipalities or communes",
      option: {
        layers: "hotmaps:" + "tbl_lau1_2",
        format: "image/png8",
        transparent: true,
        version: "1.3.0",
        zIndex: 5,
      },
    },
    {
      label: "Hectare",
      option: {
        layers: "hotmaps:" + "nuts" + 0,
        format: "image/png8",
        transparent: true,
        version: "1.3.0",
        cql_filter: "stat_levl_ = " + 1 + " AND year='2013-01-01'",
        srs: "EPSG:4326",
        zIndex: 5,
      },
    },
  ],
  cmsId: [20, 21, 22, 23, 24, 25, 26, 28],
  scenarioExplorer: "",
};
